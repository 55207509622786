.questionnaire-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 80%;
}

.question-text {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.question-subtext {
  font-size: 18px;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option-item {
  background-color: #ddd;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px;
  font-size: 18px;
  cursor: pointer;
}

.option-item:hover {
  background-color: #ccc;
}

.option-item.selected {
  background-color: #555;
  color: #fff;
}

.next-button {
  background-color: #555555;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.back-button {
  background-color: #555;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.title {
  background-color: lightblue;
  padding: 10px;
  text-align: center;
}
.Questiontitle {
  /* background-color: rgb(87, 186, 219); */
  padding: 10px;
  text-align: center;
}
.AnswersBody {
  padding: 10px;
  /* text-align: center; */
}