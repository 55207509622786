.questionnaire-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 80%;
  }
  
  .question-text {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  
  .question-subtext {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .option-item {
    border :#888;
    border-style: solid; 
    padding: 5px;
    margin: 5px;
    border-radius: 7px; 
    background-color: white;
  }

  .option-item-text {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: normal;
    display: 'block';
    text-align: left;
  }

  .open-text {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: normal;
    display: 'block';
    height:150px;
  }

  .options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
   
  .option-item:hover {
    background-color: #ccc;
  }
  
  .option-item.selected {
    background-color: #555;
    color: #fff;
  }
  
  .next-button {
    background-color: #555555;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .back-button {
    background-color: #555;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .title {
    background-color: lightblue;
    padding: 10px;
    text-align: start;
  }
  .Questiontitle {
    /* background-color: rgb(87, 186, 219); */
    padding: 10px;
    text-align: start;
  }
  .AnswersBody {
    padding: 10px;
    /* transition: opacity 0.5s ease-out; */
    /* text-align: center; */
  }